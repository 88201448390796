/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStyleConfig } from "@chakra-ui/react";

const Button = defineStyleConfig({
    baseStyle: {
        fontFamily: "Inter, sans-serif",
        opacity: "1",
        borderRadius: 12,
        whiteSpace: "normal",
        wordWrap: "break-word",
        _loading: {
            opacity: "1"
        }
    },
    sizes: {
        sm: {
            fontSize: 13,
            h: 10,
            w: "auto",
            py: 3.5,
            px: 8
        },
        md: {
            fontSize: 16,
            h: "auto",
            w: "auto",
            py: 3.5,
            px: "2.4rem"
        }
    },
    variants: {
        outline: {
            bg: "white"
        },
        link: {
            color: "royalBlue.500",
            _active: {
                color: "royalBlue.500"
            }
        },
        textLink: {
            height: "auto",
            fontWeight: "normal",
            color: "deepBlue.500",
            fontSize: 14,
            textDecoration: "underline",
            fontFamily: "body",
            padding: 0,
            _hover: {
                textDecoration: "underline"
            },
            _disabled: {
                color: "gray.600",
                _hover: {
                    cursor: "not-allowed",
                    textDecoration: "underline"
                }
            }
        },
        primary: {
            minH: "58px",
            bg: "deepBlue.500",
            color: "white.500",
            _hover: {
                bg: "deepBlue.600",
                _disabled: {
                    bg: "gray.400"
                }
            },
            _disabled: {
                bg: "gray.400",
                color: "ebonyClay.400",
                cursor: "not-allowed"
            },
            _loading: {
                bg: "deepBlue.500",
                color: "white.500",
                border: "none"
            }
        },
        primaryHollow: {
            minH: "58px",
            bg: "white.500",
            border: "1px solid",
            borderColor: "deepBlue.500",
            color: "deepBlue.500",
            _hover: {
                bg: "white.500",
                color: "deepBlue.500",
                borderColor: "deepBlue.500"
            },
            _disabled: {
                bg: "gray.400",
                color: "ebonyClay.400",
                border: "none"
            },
            _loading: {
                bg: "white.500",
                color: "deepBlue.500",
                borderColor: "deepBlue.500",
                border: "1px solid"
            }
        },
        secondary: (props: any) => ({
            maxH: "48px",
            bg: props.isFilled ? "ebonyClay.500" : "white.500",
            border: "1px solid",
            borderColor: props.isFilled ? "deepBlue.500" : "white.500",
            color: props.isFilled ? "white.500" : "deepBlue.500",
            _hover: {
                bg: props.isFilled ? "ebonyClay.600" : "white.500",
                color: props.isFilled ? "white.500" : "ebonyClay.600"
            },
            _disabled: {
                bg: props.isFilled ? "gray.400" : "white.500",
                color: "slateGray.600",
                border: "1px solid",
                borderColor: "gray.400"
            },
            _loading: {
                bg: props.isFilled ? "ebonyClay.500" : "white.500",
                color: props.isFilled ? "white.500" : "ebonyClay.500",
                borderColor: "ebonyClay.500",
                border: "1px solid"
            }
        }),
        tertiary: (props: any) => ({
            maxH: "39px",
            bg: props.isFilled ? "ebonyClay.500" : "white.500",
            border: "1px solid",
            borderColor: props.isFilled ? "ebonyClay.500" : "white.500",
            color: props.isFilled ? "white.500" : "ebonyClay.500",
            _hover: {
                bg: props.isFilled ? "ebonyClay.600" : "white.500",
                color: props.isFilled ? "white.500" : "ebonyClay.600"
            },
            _disabled: {
                bg: props.isFilled ? "gray.400" : "white.500",
                color: "slateGray.600",
                border: "1px solid",
                borderColor: "gray.400"
            },
            _loading: {
                bg: props.isFilled ? "deepBlue.500" : "white.500",
                color: props.isFilled ? "white.500" : "deepBlue.500",
                borderColor: "deepBlue.500",
                border: "1px solid"
            }
        }),
    }
});

export default Button;
