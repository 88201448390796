import {
    fetchProductFamilies,
    postCheckoutState
} from "@/store/features/checkout/checkoutThunks";
import { CheckoutPagesEnum } from "@/ts/enums/CheckoutPagesEnum";
import { CHECKOUT_STUDENT_FLOW } from "@/ts/enums/CheckoutStudentFlowEnum";
import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";
import { ProductFamilyApiInterface } from "@/ts/interfaces/Checkout/api/ProductFamilyApiInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const urlParams = new URLSearchParams(window.location.search);
const selectedState = urlParams.get("selectedState");
const selectedCountry = urlParams.get("selectedCountry");

interface UserSettingsState {
    productFamilies: ProductFamilyInterface[] | null;
    productFamiliesResponse: ProductFamilyApiInterface[] | null;
    userSelectedCountry: string;
    userSelectedState: string;
    userSelectedLang: string;
    currency: "AUD" | "USD";
    nextAttemptPage: CheckoutPagesEnum | null;
    parentUserUuid: string;
    isNewsletterSubEnabled: boolean;
    canShowExistingUserPage: boolean;
    checkoutToken: string | null;
    studentFlow: CHECKOUT_STUDENT_FLOW | null;
    isProvidedQueryParams: boolean;
    canAddonSelectionSkip: boolean;

    productFamiliesRequestStatus: REQUEST_STATUS;
    checkoutStateRequestStatus: REQUEST_STATUS;
}

const initialState: UserSettingsState = {
    productFamilies: null,
    productFamiliesResponse: null,
    userSelectedCountry: selectedCountry ?? "AU",
    userSelectedState: selectedState ?? "",
    userSelectedLang: "en",
    currency: "AUD",
    nextAttemptPage: null,
    parentUserUuid: uuidv4(),
    isNewsletterSubEnabled: false,
    canShowExistingUserPage: false,
    checkoutToken: null,
    studentFlow: null,
    isProvidedQueryParams: true,
    canAddonSelectionSkip: false,

    productFamiliesRequestStatus: REQUEST_STATUS.IDLE,
    checkoutStateRequestStatus: REQUEST_STATUS.IDLE
};

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState,
    reducers: {
        setProductFamilies(
            state,
            action: PayloadAction<ProductFamilyInterface[]>
        ) {
            state.productFamilies = action.payload;
        },
        setProductFamiliesResponse(
            state,
            action: PayloadAction<ProductFamilyApiInterface[]>
        ) {
            state.productFamiliesResponse = action.payload;
        },
        setUserSelectedCountry(state, action: PayloadAction<string>) {
            state.userSelectedCountry = action.payload;
        },
        setUserSelectedState(state, action: PayloadAction<string>) {
            state.userSelectedState = action.payload;
        },
        setUserSelectedLang(state, action: PayloadAction<string>) {
            state.userSelectedLang = action.payload;
        },
        setCurrency(state, action: PayloadAction<"AUD" | "USD">) {
            state.currency = action.payload;
        },
        setNextAttemptPage(
            state,
            action: PayloadAction<CheckoutPagesEnum | null>
        ) {
            state.nextAttemptPage = action.payload;
        },
        setParentUserUuid(state, action: PayloadAction<string>) {
            state.parentUserUuid = action.payload;
        },
        setIsNewsletterSubEnabled(state, action: PayloadAction<boolean>) {
            state.isNewsletterSubEnabled = action.payload;
        },
        setCanShowExistingUserPage(state, action: PayloadAction<boolean>) {
            state.canShowExistingUserPage = action.payload;
        },
        setCheckoutToken(state, action: PayloadAction<string | null>) {
            state.checkoutToken = action.payload;
        },
        setStudentFlow(
            state,
            action: PayloadAction<CHECKOUT_STUDENT_FLOW | null>
        ) {
            state.studentFlow = action.payload;
        },
        setIsProvidedQueryParams(state, action: PayloadAction<boolean>) {
            state.isProvidedQueryParams = action.payload;
        },
        setCanAddonSelectionSkip(state, action: PayloadAction<boolean>) {
            state.canAddonSelectionSkip = action.payload;
        },
        resetUserSettingsState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            // Fetch product families
            .addCase(fetchProductFamilies.pending, state => {
                state.productFamiliesRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(fetchProductFamilies.fulfilled, (state, action) => {
                state.productFamiliesRequestStatus = REQUEST_STATUS.SUCCESS;
                state.productFamilies = action.payload.productFamilies;
                state.productFamiliesResponse =
                    action.payload.productFamiliesResponse;
            })
            .addCase(fetchProductFamilies.rejected, state => {
                state.productFamiliesRequestStatus = REQUEST_STATUS.ERROR;
                state.productFamilies = null;
                state.productFamiliesResponse = null;
            })

            // Post checkout state data
            .addCase(postCheckoutState.pending, state => {
                state.checkoutStateRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(postCheckoutState.fulfilled, (state, action) => {
                state.checkoutStateRequestStatus = REQUEST_STATUS.SUCCESS;
                state.checkoutToken = action.payload.token;
            })
            .addCase(postCheckoutState.rejected, state => {
                state.checkoutStateRequestStatus = REQUEST_STATUS.ERROR;
            });
    }
});

export const {
    setProductFamilies,
    setUserSelectedCountry,
    setUserSelectedState,
    setUserSelectedLang,
    setCurrency,
    setNextAttemptPage,
    setParentUserUuid,
    setIsNewsletterSubEnabled,
    setCanShowExistingUserPage,
    resetUserSettingsState,
    setCheckoutToken,
    setStudentFlow,
    setIsProvidedQueryParams,
    setCanAddonSelectionSkip
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
